import { useState, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import {
  BoltIcon,
  EyeSlashIcon,
  FolderIcon,
  HomeModernIcon,
  ChevronDownIcon,
  TvIcon,
  PaintBrushIcon,
  SwatchIcon,
  Squares2X2Icon,
  MapPinIcon,
  SunIcon,
  PhotoIcon,
  ChartBarIcon,
  CheckCircleIcon as CircleCheckOutline
} from '@heroicons/react/24/outline';
import { CheckCircleIcon as CircleCheckSolid } from '@heroicons/react/24/solid';
import Toggle from '../components/ui/Toggle/Toggle';
import SubmitJob from 'components/SubmitJob';
import SelectFile2 from 'components/SelectFile2';
import SelectCustomStyle from 'components/SelectCustomStyle';
import SelectFileImages from 'components/SelectFileImages';
import { trackEvent } from '../utils/analytics';
import InputFile2 from 'components/InputFile2';
import InputFile3 from 'components/InputFile3';
import AdvancedTabs from './AdvancedTabs';
import { useUserCon } from '../contexts/UserContext';
import { useImage } from '../contexts/ImageContext';
import { useRender } from '../contexts/RenderContext';
import Slider from '@mui/material-next/Slider';
import { useTranslation, Trans } from 'next-i18next';

import { getData, evaluateFileType, getURL } from 'utils/helpers';

const InputPanel = ({
  uploading,
  selectFile,
  checkIfLoggedIn,
  selectedRoom,
  setSelectedRoom,
  roomTypes,
  setOpenModal,
  selectedStyle,
  setSelectedStyle,
  environment,
  setEnvironment,
  setColor,
  selectedColor,
  availableStyles,
  selectedRedesign,
  setSelectedRedesign,
  redesignTypes,
  colors,
  selectedProject,
  setSelectedProject,
  availableProjects,
  setOpenProjectModal,
  selectedQuality,
  setSelectedQuality,
  qualityOptions,
  selectedPrivacy,
  setSelectedPrivacy,
  privacyTypes,
  editingMode,
  setEditingMode,
  aspectRatioCode,
  showCurrentRender,
  setUploadingState,
  setSignup,
  setPreviousFile,
  setPreviousUrl,
  previousFile,
  previousUrl,
  checkHasMoreRenders,
  setActiveInput,
  quickFile,
  setQuickFile,
  quickImageUrl,
  setQuickImageUrl,
  selectedArea,
  setSelectedArea,
  areaOptions,
  myStyles,
  setMyStyles,
  communityStyles,
  setCommunityStyles,
  styleType,
  setStyleType,
  selectedCustomStyle,
  setSelectedCustomStyle,
  selectedMyStyle,
  setSelectedMyStyle,
  selectedBrushType
}) => {
  const { user, subscription, userDetails } = useUserCon();
  const { imageUrl, file } = useImage();

  const canvasRef2 = useRef(1);

  const { hasMoreRenders } = useRender();
  const [intensity, setIntensity] = useState(50);
  const [selectedSample, setSelectedSample] = useState(null);

  const numOptions = [
    { display_name: '1 Render', name: 1, pro_feature: false },
    { display_name: '2 Renders', name: 2, pro_feature: true }
  ];

  const { t } = useTranslation('common');

  const [numRenders, setNumRenders] = useState(numOptions[0]);

  // const [styleType, setStyleType] = useState('classical');

  // const [selectedCustomStyle, setSelectedCustomStyle] = useState(
  //   communityStyles[0]
  // );

  // const [selectedMyStyle, setSelectedMyStyle] = useState(myStyles[0]);

  useEffect(() => {
    if (
      (selectedMyStyle === null || selectedMyStyle === undefined) &&
      myStyles.length > 0
    ) {
      setSelectedMyStyle(myStyles[0]);
    }
  }, [myStyles, selectedMyStyle]);

  useEffect(() => {
    if (
      (selectedCustomStyle === null || selectedCustomStyle === undefined) &&
      communityStyles.length > 0
    ) {
      setSelectedCustomStyle(communityStyles[0]);
    }
  }, [communityStyles]);

  useEffect(() => {
    if (editingMode === 'quickstyles') {
      setStyleType('classical');
    }
  }, [editingMode]);

  const showArea = (selectedRedesign) => {
    if (
      selectedRedesign.display_name === 'Change Color' ||
      selectedRedesign.display_name === 'Improve & Replace' ||
      selectedRedesign.display_name === 'Empty Room Beta'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const selectQuickFile = (e, index) => {
    trackEvent('select_file', {
      category: 'engagement',
      label: 'select_file'
    });

    try {
      if (!e.target.files[0]) return;

      evaluateFileType(e.target.files[0]);

      // set index to the selected image

      var newQuickImageUrl = [...quickImageUrl];
      newQuickImageUrl[index] = e.target.files[0];

      setQuickFile(newQuickImageUrl);

      const img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);

      img.onload = function () {
        const name = e.target.files[0].name;
        const type = e.target.files[0].type;

        const width = img.naturalWidth;
        const height = img.naturalHeight;

        resizeImage(img, height, width, name, type, index);
      };
    } catch (error) {
      console.log(error);
    }
  };

  const compressImage = (canvas, quality = 0.8) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/webp',
        quality
      );
    });
  };

  const resizeImage = async (img, height, width, name, type, index) => {
    // Set New Image Dimensions (must keep aspect ratio and lowest value must be 512px)

    // determine orientation

    const supported_configs_low = {
      square: { width: 512, height: 512 },
      phone: { width: 512, height: 912 },
      landscape: { width: 912, height: 512 },
      landscape_small: { width: 768, height: 512 },
      tall_square: { width: 576, height: 768 }
    };

    const supported_configs_high = {
      square: { width: 768, height: 768 },
      phone: { width: 576, height: 1024 },
      landscape: { width: 1024, height: 576 },
      landscape_small: { width: 768, height: 512 },
      tall_square: { width: 576, height: 768 }
    };

    const aspectRatio = height / width;

    const aspectToConfig = {
      square: 1,
      phone: 1.77,
      landscape: 0.56,
      landscape_small: 512 / 768,
      tall_square: 1.33
    };

    // loop through supported configs and find the closest match

    try {
      let closestMatch = null;

      for (const [key, value] of Object.entries(aspectToConfig)) {
        if (closestMatch == null) {
          closestMatch = key;
        } else {
          if (
            Math.abs(aspectRatio - value) <
            Math.abs(aspectRatio - aspectToConfig[closestMatch])
          ) {
            closestMatch = key;
          }
        }
      }

      const lever = 'low';

      const active_config =
        lever == 'low' ? supported_configs_low : supported_configs_high;

      var newWidth = active_config[closestMatch].width;
      var newHeight = active_config[closestMatch].height;
    } catch (error) {
      console.log('error', error);
      // throw Error(error.message);

      const target_size = 640;

      var newWidth =
        width > height
          ? Math.floor((target_size * width) / height)
          : target_size;
      var newHeight =
        height > width
          ? Math.floor((target_size * height) / width)
          : target_size;

      console.log('using previous config image');
    }

    // Resize Image
    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;

    canvas.current = canvasRef2;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, newWidth, newHeight);

    // Set Preview url
    const compressedBlob = await compressImage(canvas);

    // Set File to resized image

    var newQuickFile = [...quickFile];
    newQuickFile[index] = new File([compressedBlob], name, {
      type: 'image/webp'
    });

    setQuickFile(newQuickFile);

    var newQuickImageUrl = [...quickImageUrl];
    newQuickImageUrl[index] = canvas.toDataURL('image/webp');

    setQuickImageUrl(newQuickImageUrl);
  };

  // let roomOptions = roomTypes;

  const handleIntensityChange = (e) => {
    e.preventDefault();
    const value = parseFloat(e.target.value);

    setIntensity(value);
  };

  const handleMoreMixImages = () => {
    if (quickImageUrl.length < 4) {
      var newQuickImageUrl = [quickImageUrl[0], null, null, null];
      var newQuickFile = [quickFile[0], null, null, null];
    } else {
      var newQuickImageUrl = [quickImageUrl[0]];
      var newQuickFile = [quickFile[0]];
    }

    setQuickImageUrl(newQuickImageUrl);
    setQuickFile(newQuickFile);
  };

  const clearMixImages = () => {
    if (quickImageUrl.length < 4) {
      var newQuickImageUrl = [null];
      var newQuickFile = [null];
    } else {
      var newQuickImageUrl = [null, null, null, null];
      var newQuickFile = [null, null, null, null];
    }

    setQuickImageUrl(newQuickImageUrl);
    setQuickFile(newQuickFile);
  };

  const completedJobs = userDetails?.completed_jobs;

  // use effect to change roomOptions when environment changes

  // go to page using router

  const sampleImageOne = '';

  const samples = [
    'https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/9749e004-5eae-4f21-952a-c4ae5f3bfa00/square',
    'https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/dcfbb879-0a6d-4bda-eefe-f1a80f50ad00/square',
    'https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/748f29d3-96b5-4054-e9d7-7bad1d452600/square',
    'https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/c9f6f5e7-c523-4677-b1dd-181e59d76000/square'
  ];

  const sampleData = [
    { name: 'Living Room', display_name: 'Living Room', description: '' },
    { name: 'Bedroom', display_name: 'Bedroom', description: '' },
    { name: 'Kitchen', display_name: 'Kitchen', description: '' },
    { name: 'Bathroom', display_name: 'Bathroom', description: '' }
  ];

  const handleSelectSample = (sample, i) => {
    setSelectedSample(i);
    setActiveInput(sample, 'square');
    setSelectedRoom(sampleData[i]);
  };

  return (
    <Transition
      show={
        !!(
          (user && uploading === 'blank' && hasMoreRenders) ||
          (uploading === 'completed' && editingMode === 'advanced')
        )
      }
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-0 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      className={'w-full my-2 z-30 relative max-w-2xl sm:m-auto'}
    >
      {!(uploading === 'completed' && editingMode === 'advanced') && (
        <div className="mt-4">
          <AdvancedTabs
            editingMode={editingMode}
            passEditingMode={setEditingMode}
          ></AdvancedTabs>
          <div className="font-bold text[#060D21] text-[1em] pt-3 pb-3">
            {t('Upload your current interior')}
          </div>
          <InputFile2
            fileselector={selectFile}
            imageurl={imageUrl}
            loginCheck={checkIfLoggedIn}
          />
          {completedJobs < 2 && (
            <div>
              <div className="hp-center-text mt-2">
                <span>or</span>
              </div>
              <div className="font-bold text-[1em] text[#060D21] pt-2 pb-2">
                {t('Try with a sample image')}
              </div>
              <div className="grid grid-cols-4 gap-1 md:grid-cols-4 items-center md:flex md:flex-row md:justify-start md:gap-2 justify-center">
                {samples.map((sample, index) => (
                  <div
                    onClick={() => handleSelectSample(sample, index)}
                    key={index}
                    className="flex justify-center items-center  "
                  >
                    <img
                      className={`rounded-lg  ${
                        selectedSample == index
                          ? 'border-indigo-600 border-2'
                          : 'filter saturate-50'
                      }  `}
                      src={sample}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {editingMode === 'quickstyles' && (
            <div>
              <div className="hp-center-text mt-2">
                <span>and</span>
              </div>
              <div className="flex flex-row font-bold text-[1em] text[#060D21] pt-2 pb-5 content-between place-content-between align-bottom ">
                <span className="self-end">{t('Bring your Style')}</span>
                <div className="flex gap-2">
                  <span
                    onClick={clearMixImages}
                    className="text-xs self-end bg-gray-200 p-1 rounded-md"
                  >
                    clear
                  </span>
                  <span
                    onClick={handleMoreMixImages}
                    className="text-xs self-end bg-gray-200 p-1 rounded-md"
                  >
                    {quickImageUrl.length > 1 ? 'less images' : 'more images'}
                  </span>
                </div>
              </div>
              <div
                className={
                  quickImageUrl.length > 1
                    ? 'grid grid-rows-2 grid-flow-col  md:grid-rows-1 gap-2'
                    : 'grid grid-cols-2 md:grid-cols-2 gap-2'
                }
              >
                {quickImageUrl.map((image, index) => (
                  <div className="" key={index + quickImageUrl[index]}>
                    <InputFile3
                      fileselector={(e) => selectQuickFile(e, index)}
                      imageurl={image}
                      loginCheck={checkIfLoggedIn}
                    />
                  </div>
                ))}
              </div>
              <div className="text-sm mt-2 text-slate-800 ">
                {quickImageUrl.length > 1 ? 'use between 1 and 4 images' : ''}
              </div>
            </div>
          )}
          {(editingMode === 'basic' || editingMode === 'quickstyles') && (
            <div>
              <div className="font-bold text-[1em] text[#060D21] pt-3 pb-1">
                {t('Select Room Location')}
              </div>
              <div className="flex gap-x-4">
                <div
                  onClick={() => {
                    setEnvironment({
                      display_name: 'Interior',
                      name: 'Interior'
                    });
                  }}
                  className={`flex items-center gap-x-1 text-sm cursor-pointer ${
                    environment.display_name === 'Interior'
                      ? 'text-slate-700'
                      : 'text-[#060D2166]'
                  }`}
                >
                  {environment.display_name === 'Interior' ? (
                    <CircleCheckSolid className="h-[28px]" />
                  ) : (
                    <CircleCheckOutline className="h-[28px]" />
                  )}
                  Interior
                </div>
                <div
                  onClick={() => {
                    setEnvironment({
                      display_name: 'Exterior',
                      name: 'Exterior'
                    });
                  }}
                  className={`flex items-center gap-x-1 text-sm cursor-pointer ${
                    environment.display_name === 'Exterior'
                      ? 'text-slate-700'
                      : 'text-[#060D2166]'
                  }`}
                >
                  {environment.display_name === 'Exterior' ? (
                    <CircleCheckSolid className="h-[28px]" />
                  ) : (
                    <CircleCheckOutline className="h-[28px]" />
                  )}
                  Exterior
                </div>
                <div
                  onClick={() => {
                    setEnvironment({
                      display_name: 'Commercial',
                      name: 'Commercial'
                    });
                  }}
                  className={`flex items-center gap-x-1 text-sm cursor-pointer ${
                    environment.display_name === 'Commercial'
                      ? 'text-slate-700'
                      : 'text-[#060D2166]'
                  }`}
                >
                  {environment.display_name === 'Commercial' ? (
                    <CircleCheckSolid className="h-[28px]" />
                  ) : (
                    <CircleCheckOutline className="h-[28px]" />
                  )}
                  Commercial
                </div>
              </div>

              <div className="font-bold text-[1em] text[#060D21] pt-3 pb-2">
                {t('Personalize your space')}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="flex flex-col w-full text-sm">
        {/* {editingMode === 'advanced' && (
          <div className='m-2 text-slate-600'>
            Use Advanced mode to Edit a specific part of your image. Tap using our advanced selection software and replace specific furniture, walls or floors. 
          </div>
          )
        } */}
        {(editingMode === 'basic' ||
          editingMode === 'quickstyles' ||
          editingMode == 'quickstyles' ||
          (uploading === 'completed' && editingMode === 'advanced')) && (
          <div className="text-slate-700 bg-neutral-100 p-2 sm:p-5 rounded-xl">
            <div className="flex flex-row pb-2">
              <div className="font-bold leading-5 flex w-32 items-center">
                <div className="w-6 align-middle">
                  <Squares2X2Icon></Squares2X2Icon>
                </div>
                <div className="align-middle ml-2">{t('Type')}</div>
              </div>
              <div className="w-full">
                <SelectFile2
                  selectedInput={selectedRedesign}
                  passSelectedInput={setSelectedRedesign}
                  inputTypes={redesignTypes}
                  passOpenModal={setOpenModal}
                  subscription={subscription}
                ></SelectFile2>
              </div>
            </div>
            {showArea(selectedRedesign) && (
              <div className="flex flex-row pb-2">
                <div className="font-bold leading-5 flex w-32 items-center">
                  <div className="w-6 align-middle">
                    <MapPinIcon></MapPinIcon>
                  </div>
                  <div className="align-middle ml-2">{t('Change Area')}</div>
                </div>
                <div className="w-full">
                  <SelectFile2
                    selectedInput={selectedArea}
                    passSelectedInput={setSelectedArea}
                    inputTypes={areaOptions}
                    passOpenModal={setOpenModal}
                    subscription={subscription}
                  ></SelectFile2>
                </div>
              </div>
            )}
            <div className="flex flex-row pb-2">
              <div className="font-bold leading-5 flex w-32 items-center">
                <div className="w-6 align-middle">
                  <HomeModernIcon></HomeModernIcon>
                </div>
                <div className="align-middle ml-2">{t('Room')}</div>
              </div>
              <div className="w-full">
                <SelectFile2
                  selectedInput={selectedRoom}
                  passSelectedInput={setSelectedRoom}
                  inputTypes={roomTypes}
                  passOpenModal={setOpenModal}
                  subscription={subscription}
                ></SelectFile2>
              </div>
            </div>
            {/* <div className="flex flex-row pb-2">
              <div className="font-bold leading-5 flex w-32 items-center">
                <div className="w-6 align-middle">
                  <PaintBrushIcon></PaintBrushIcon>
                </div>
                <div className="align-middle ml-2">Style</div>
              </div>
              <div className="w-full">
                <SelectFile2
                  selectedInput={selectedStyle}
                  passSelectedInput={setSelectedStyle}
                  inputTypes={availableStyles}
                  passOpenModal={setOpenModal}
                  subscription={subscription}
                ></SelectFile2>
              </div>
            </div> */}
            {/* {editingMode !== 'quickstyles' && (
              <div className="mb-0 flex flex-col align-center ">
                <div className=" self-center  bg-white rounded-lg p-0.5 flex   mb-2 rounded-full">
                  <button
                    onClick={() => setStyleType('classical')}
                    type="button"
                    className={`${
                      styleType === 'classical'
                        ? 'relative  bg-slate-700 text-white border-indigo-800 shadow-md rounded-full'
                        : 'bg-neutral-100 rounded-full ml-0.5 relative border border-transparent text-slate-600'
                    } rounded-md m-1  py-2 text-xs font-medium whitespace-nowrap focus:outline-none focus:ring-0 sm:w-auto px-2`}
                  >
                    {t('traditional')}
                  </button>
                  {myStyles.length > 0 && (
                    <button
                      onClick={() => setStyleType('my styles')}
                      type="button"
                      className={`${
                        styleType === 'my styles'
                          ? 'relative  bg-slate-700 text-white border-indigo-800 shadow-sm rounded-full'
                          : 'bg-neutral-100 rounded-full ml-0.5 relative  border border-transparent text-slate-600'
                      } rounded-md m-1  py-2 text-xs font-medium whitespace-nowrap focus:outline-none focus:ring-0 sm:w-auto px-2`}
                    >
                      {t('my styles')}
                    </button>
                  )}
                  <button
                    onClick={() => setStyleType('community')}
                    type="button"
                    className={`${
                      styleType === 'community'
                        ? 'relative  bg-slate-700 text-white border-indigo-800 shadow-sm rounded-full'
                        : 'bg-neutral-100 rounded-full ml-0.5 relative border border-transparent text-slate-600'
                    } rounded-md m-1  py-2 text-xs font-medium whitespace-nowrap focus:outline-none focus:ring-0 sm:w-auto px-2`}
                  >
                    {t('community')}
                  </button>
                </div>
              </div>
            )} */}

            {editingMode !== 'quickstyles' && (
              <div className="flex flex-row pb-2">
                <div className="font-bold leading-5 flex w-32 items-center">
                  <div className="w-6 align-middle">
                    {/* <PaintBrushIcon></PaintBrushIcon> */}
                    <MyDropdown
                      translator={t}
                      setStyleType={setStyleType}
                      showMyStyles={myStyles.length > 0}
                      showCommunity={communityStyles.length > 0}
                      styleType={styleType}
                    />
                  </div>
                  {/* <div className="align-middle ml-2">{t('Style')}</div> */}
                </div>
                <div className="w-full flex flex-col">
                  {styleType === 'classical' && (
                    <SelectFileImages
                      selectedInput={selectedStyle}
                      passSelectedInput={setSelectedStyle}
                      inputTypes={availableStyles}
                      passOpenModal={setOpenModal}
                      subscription={subscription}
                    ></SelectFileImages>
                  )}

                  {styleType === 'my styles' &&
                    selectedMyStyle &&
                    myStyles.length > 0 && (
                      <SelectCustomStyle
                        selectedInput={selectedMyStyle}
                        passSelectedInput={setSelectedMyStyle}
                        inputTypes={myStyles}
                        passOpenModal={setOpenModal}
                        subscription={subscription}
                      ></SelectCustomStyle>
                    )}

                  {styleType === 'community' &&
                    selectedCustomStyle &&
                    communityStyles.length > 0 && (
                      <SelectCustomStyle
                        selectedInput={selectedCustomStyle}
                        passSelectedInput={setSelectedCustomStyle}
                        inputTypes={communityStyles}
                        passOpenModal={setOpenModal}
                        subscription={subscription}
                      ></SelectCustomStyle>
                    )}
                </div>
              </div>
            )}
            <div className="flex flex-row pb-2">
              <div className="font-bold leading-5 flex w-32 items-center">
                <div className="w-6 align-middle">
                  <SwatchIcon></SwatchIcon>
                </div>
                <div className="align-middle ml-2">{t('Color Palette')}</div>
              </div>
              <div className="w-full">
                <SelectFile2
                  selectedInput={selectedColor}
                  passSelectedInput={setColor}
                  inputTypes={colors}
                  passOpenModal={setOpenModal}
                  subscription={subscription}
                ></SelectFile2>
              </div>
            </div>
            <div className="flex flex-row pb-2">
              <div className="font-bold leading-5 flex w-32 items-center">
                <div className="w-6 align-middle">
                  <BoltIcon></BoltIcon>
                </div>
                <div className="align-middle ml-2">
                  {t('Number of Renders')}
                </div>
              </div>
              <div className="w-full">
                <SelectFile2
                  selectedInput={numRenders}
                  passSelectedInput={setNumRenders}
                  inputTypes={numOptions}
                  passOpenModal={setOpenModal}
                  subscription={subscription}
                ></SelectFile2>
              </div>
            </div>
            {(editingMode === 'basic' || editingMode === 'quickstyles') && (
              <div>
                {subscription && (
                  <div>
                    <div className="flex flex-row pb-2">
                      <div className="font-bold leading-5 flex w-32 items-center">
                        <div className="w-6 align-middle">
                          <FolderIcon></FolderIcon>
                        </div>
                        <div className="align-middle ml-2">{t('Project')}</div>
                      </div>
                      <div className="w-full">
                        <SelectFile2
                          selectedInput={selectedProject}
                          passSelectedInput={setSelectedProject}
                          inputTypes={availableProjects}
                          passOpenModal={setOpenModal}
                          customModal={setOpenProjectModal}
                          subscription={subscription}
                        ></SelectFile2>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-row pt-3 pb-2">
                  <div className="font-bold leading-5 flex w-60 items-center">
                    <div className="w-6 align-middle">
                      <PhotoIcon></PhotoIcon>
                    </div>
                    <div className="align-middle ml-2">
                      {t('High Quality')}
                      {subscription && (
                        <span className="ml-1 text-xs">(40s)</span>
                      )}
                    </div>
                    <span className="ml-2 rounded-lg bg-[#D6A704] px-1.5 font-bold py-0.5 text-xs font-medium text-white">
                      Pro
                    </span>
                  </div>
                  <div className="absolute right-5">
                    <Toggle
                      selectedInput={selectedQuality}
                      passSelectedInput={setSelectedQuality}
                      inputTypes={qualityOptions}
                      passOpenModal={setOpenModal}
                      subscription={subscription}
                    ></Toggle>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-row pt-3 w-full">
              <div className="font-bold leading-5 flex w-60 items-center">
                <div className="w-6 align-middle">
                  <EyeSlashIcon></EyeSlashIcon>
                </div>
                <div className="align-middle ml-2">{t('Private Render')}</div>
                <span className="ml-2 rounded-lg bg-[#D6A704] px-1.5 font-bold py-0.5 text-xs font-medium text-white">
                  Pro
                </span>
              </div>
              <div className="absolute right-5">
                <Toggle
                  selectedInput={selectedPrivacy}
                  passSelectedInput={setSelectedPrivacy}
                  inputTypes={privacyTypes}
                  passOpenModal={setOpenModal}
                  subscription={subscription}
                  privacy={true}
                ></Toggle>
              </div>
            </div>
          </div>
        )}
      </div>

      {(editingMode === 'basic' || editingMode === 'quickstyles') && (
        <div>
          <div className="font-bold text-[1em] text[#060D21] pt-4 ">
            {t('AI Intensity')}
          </div>
          <div className="flex flex-col relative -mt-1">
            <Slider
              max={100}
              min={0}
              step={5}
              value={intensity}
              onChange={handleIntensityChange}
              valueLabelDisplay="auto"
              marks
              sx={{
                color: '#F1148B'
              }}
              // color="primary"
            />
            <div className="flex flex-row justify-between -mt-2 text-sm sm:text-base">
              <div
                className={`${
                  intensity > 30
                    ? 'text-[#060D2166]'
                    : 'font-bold text-slate-700'
                }`}
              >
                {t('Low')}
              </div>
              <div
                className={`${
                  intensity > 30 && intensity < 70
                    ? 'font-bold text-slate-700'
                    : 'text-[#060D2166]'
                }`}
              >
                {t('Recommended')}
              </div>
              <div
                className={`${
                  intensity > 70
                    ? 'font-bold text-slate-700'
                    : 'text-[#060D2166]'
                }`}
              >
                {t('High')}
              </div>
            </div>
            {/* <div className="w-full py-2">
                <input
                  id="steps-range"
                  type="range"
                  min="0"
                  max="100"
                  value={intensity}
                  onChange={handleIntensityChange}
                  step="25"
                  className="w-full h-2 rounded-lg appearance-none cursor-pointer bg-[#4138C2]"
                />
              </div> */}
          </div>
        </div>
      )}

      <div className="flex justify-center mt-2">
        <SubmitJob
          style={selectedStyle.name}
          user={user}
          file={file}
          roomtype={selectedRoom.name}
          color={selectedColor.display_name}
          quality={selectedQuality.code}
          privacy={selectedPrivacy.code}
          rendertype={selectedRedesign.name}
          project={selectedProject.display_name}
          intensity={intensity}
          aspect={aspectRatioCode}
          uploading={uploading}
          handleadd={showCurrentRender}
          passUploadingState={setUploadingState}
          passSignupState={setSignup}
          buttontext={t('Create your new Interior')}
          setPreviousFile={setPreviousFile}
          setPreviousUrl={setPreviousUrl}
          updateRenders={checkHasMoreRenders}
          previousFile={previousFile}
          previousUrl={previousUrl}
          subscription={subscription}
          userDetails={userDetails}
          editingMode={editingMode}
          numRenders={numRenders.name}
          className=""
          fileQuick={quickFile}
          imageUrlQuick={quickImageUrl}
          setQuickImageUrl={setQuickImageUrl}
          styleType={styleType}
          selectedMyStyle={selectedMyStyle}
          selectedCustomStyle={selectedCustomStyle}
          selectedBrushType={selectedBrushType}
          maskPrompt={
            selectedRedesign.display_name == 'Improve & Replace' ||
            selectedRedesign.display_name == 'Change Color'
              ? selectedArea.mask_prompt
              : ''
          }
        />
      </div>
    </Transition>
  );
};

export default InputPanel;

import { Menu } from '@headlessui/react';
import { t } from 'i18next';

function MyDropdown({
  translator,
  setStyleType,
  showMyStyles = true,
  showCommunity = true,
  styleType
}) {
  return (
    <Menu
      as="div"
      className="relative inline-block text-left focus:ring-0 active:ring-0 bg-slate-800 text-black rounded-lg p-2 -ml-2"
    >
      <Menu.Button className="flex flex-row w-full justify-center rounded-md  text-sm font-extrabold   border-pink-100 text-white items-center  focus:ring-0 items-center">
        <PaintBrushIcon className="h-5 " />
        <div className="align-middle ml-2 ">{translator('Style')}</div>
        <ChevronDownIcon
          className="  h-3 w-3 text-white-800"
          aria-hidden="true"
        />
      </Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute z-50 bg-white flex flex-col gap-5 p-2 rounded-lg ">
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  (active || styleType == 'classical') && 'font-extrabold'
                }`}
                onClick={() => setStyleType('classical')}
              >
                {translator('Traditional')}
              </button>
            )}
          </Menu.Item>
          <Menu.Item className={`${!showMyStyles && 'hidden'}`}>
            {({ active }) => (
              <button
                className={`${active && 'font-extrabold'}`}
                onClick={() => setStyleType('my styles')}
              >
                {translator('My Styles')}
              </button>
            )}
          </Menu.Item>
          <Menu.Item className={`${!showCommunity && 'hidden'}`}>
            {({ active }) => (
              <button
                className={`${active && 'font-extrabold'}`}
                onClick={() => setStyleType('community')}
              >
                {translator('Community')}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
