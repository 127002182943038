import {
  AdjustmentsHorizontalIcon,
  BoltIcon,
  MagnifyingGlassIcon,
  HeartIcon
} from '@heroicons/react/24/outline';

import { useRouter } from 'next/router';

const tabs = [
  { id: 'basic', name: 'Fast Mode', href: '#', icon: BoltIcon, current: true },
  {
    id: 'advanced',
    name: 'Advanced Mode',
    href: '#',
    icon: AdjustmentsHorizontalIcon,
    current: false
  },
  {
    id: 'room_advice',
    name: 'Room Advice',
    href: '#',
    icon: MagnifyingGlassIcon,
    current: false
  },
  {
    id: 'quickstyles',
    name: 'Your Style Mix',
    href: '#',
    icon: HeartIcon,
    current: false
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function updateCurrentProperty(array, id) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) {
      array[i].current = true;
    } else {
      array[i].current = false;
    }
  }
}

import { useTranslation, Trans } from 'next-i18next';
export default function AdvancedTabs({ editingMode, passEditingMode }) {
  const { t } = useTranslation('common');
  const router = useRouter();
  function changeTab(tab) {
    if (tab.id === 'room_advice') {
      // go to /roast-my-room using next router

      router.push('/roast-my-room');
    }
    passEditingMode(tab.id);
    updateCurrentProperty(tabs, tab.id);
  }
  return (
    <div className="">
      <div className="bg-white">
        <nav
          className="grid grid-cols-2 md:flex md:space-x-4 mt-3 gap-2"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              onClick={() => changeTab(tab)}
              className={classNames(
                editingMode === tab.id
                  ? 'text-white bg-gradient-to-r hanno from-purple-500 to-pink-500 hover:bg-gradient-to-l  dark:focus:ring-purple-800 font-medium rounded-lg text-sm text-center  '
                  : 'bg-neutral-100 text-slate-500',
                'group inline-flex items-center justify-center font-bold py-2 px-3 text-xs md:text-sm rounded-lg'
              )}
              aria-current={editingMode === tab.id ? 'page' : undefined}
            >
              <tab.icon
                className={classNames(
                  editingMode === tab.id ? 'text-white' : 'text-text-slate-500',
                  '-ml-0.5 mr-2 h-5 w-5 font-bold'
                )}
                aria-hidden="true"
              />
              <span>{t(tab.name)}</span>
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}
