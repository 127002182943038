import { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Toggle({
    selectedInput,
    passSelectedInput,
    inputTypes,
    passOpenModal,
    subscription,
    privacy
}) {
  const [enabled, setEnabled] = useState(false)
  const setSelectedInput = (bool) => {    
    if (!subscription) {
        passOpenModal(true);
    }
    else {
        setEnabled(bool);
        let num = Number(bool);
        let newInput = inputTypes[num];
        passSelectedInput(newInput);
    }
};
useEffect(() => {
  if(subscription && privacy){
    setEnabled(true);
  }
}, [subscription]);



  return (
    <Switch
      checked={enabled}
      onChange={setSelectedInput}
      className={classNames(
        enabled ? 'bg-indigo-600' : 'bg-[#9B9EA6]',
        'relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-4' : 'translate-x-0',
          'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  )
}